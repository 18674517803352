import React from 'react';
import './politica.css'

const Politica = (props) => (
  <div className="politica-element"  >
    <div className="politica-container" >
           
        <h4>Política de Privacidade Visitantes</h4>
        <strong> Bem-vindo à Política de Privacidade</strong>

        <p >
        Esta Política de Privacidade tem como alvo os visitantes do nosso website {props.site} <br></br>
        Última versão editada no dia 05/10/2017.
        </p>

        <p >
            Quando o usuário usa nossos serviços, confia a nós suas informações. Esta Política de Privacidade destina-se a ajudar o usuário a entender como abordamos suas informações pessoais.
            <br></br>
            Quando o usuário usa os nossos serviços, <strong>o usuário concorda com as práticas de privacidade e coleta, armazenamento e compartilhamento de dados conforme descrito nesta Política de Privacidade.</strong>
            <br></br>
            Nossa equipe de atendimento ao consumidor estará sempre disposta a esclarecer qualquer dúvida sobre nossa política de privacidade, através do canal “Contato” (https://{props.site}/).
        </p>
        




    <p className="politica-p">
        <strong>
        I – Informações coletadas
        </strong>
    </p>
 
    <ol>
        <li>
            <strong>
                Informações que o usuário decide compartilhar conosco
            </strong>
            <ul>
                <strong>Informações referentes ao usuário:</strong> Coletamos nome completo, CPF, RG, data de nascimento, endereço de e-mail, número de telefone celular e endereço. Estes dados são imprescindíveis para o cadastro do usuário e contato entre a {props.empresa} e o usuário.
            </ul>
            <ul>
                Quando o usuário entra em contato conosco de qualquer maneira, as conversas de e-mail, chat, mensagem e telefone poderão ser identificadas e armazenadas e poderão ser solicitadas pelo consumidor.
            </ul>
        </li>
        <li>
            <strong>
                Informações que obtemos quando o usuário usa nossos serviços
            </strong>
            <ul>
                <strong>
                Informações coletadas por cookies: Podemos usar cookies e outras tecnologias, como web beacons, armazenamento na web e identificadores de publicidade exclusivos, para coletar informações sobre sua atividade, navegador e dispositivo, para manter informações sobre o usuário. 
                </strong> A maioria dos navegadores é configurada, de maneira padrão, para aceitar cookies. Se o usuário preferir, geralmente é possível remover ou rejeitar os cookies do navegador através das configurações em seu navegador ou dispositivo. Ao desabilitar a opção de armazenamento de cookies em seu navegador ou aplicativo, não será possível utilizar o nosso serviço em toda sua funcionalidade. 
            </ul>
            <ul>
                <strong>Informações do log: </strong> Coletamos informações de acesso quando o usuário usa nossos serviços. Essas informações incluem, entre outras: detalhes sobre como o usuário utilizou nossos serviços, informações do dispositivo (tais como o tipo e o idioma do navegador), horários de acesso, páginas visualizadas, endereço IP, identificadores associados a cookies ou a outras tecnologias que podem identificar exclusivamente o dispositivo ou navegador e páginas que o usuário visitou antes ou depois de navegar para nosso site.
            </ul>           
        </li>
        <li>
            <strong>
                 Informações que coletamos de terceiros
            </strong>
            <ul>
                A {props.empresa} pode obter informações de quaisquer outras fontes de terceiros, e combiná-las com as informações que coletamos por meio de nossos serviços.
            </ul>
        </li>
    </ol>
    
    <p>
        <strong>
           II – Informações Compartilhadas
        </strong>
    </p> 
    <p>
        A {props.empresa} pode compartilhar informações sobre o usuário da seguinte maneira:
    </p>
    <ol>
        <li>
            <strong>Com nossas afiliadas:</strong> Podemos compartilhar informações com entidades pertencentes à família de empresas da {props.empresa}.
        </li>
        <li>
            <strong>Com prestadores de serviços, vendedores e parceiros:</strong> Podemos compartilhar informações sobre o usuário com provedores de serviço que realizam serviços em nosso nome, vendedores que fornecem bens através dos nossos serviços e parceiros de negócios que forneçam serviços e funcionalidade.
        </li>
        <li>
            <strong>Com terceiros por razões legais:</strong> Podemos compartilhar informações sobre o usuário se acreditarmos, razoavelmente, que a divulgação destas informações seja necessária para:
           <ol>
                <li>
                    Cumprir com todo e qualquer procedimento legal válido, solicitação governamental ou leis, regras e/ou regulamentos aplicáveis.
                </li>
                <li>
                    Investigar, reparar ou punir eventuais violações dos Termos de Serviço.
                </li>
                <li>
                    Proteger os direitos, a propriedade e a segurança de nós mesmos, de nossos usuários ou de terceiros.
                </li>
                <li>
                    Detectar e resolver todas as questões relacionadas a fraudes e segurança.
                </li>
           </ol>
        </li>
        <li>
            <strong>Com terceiros como parte de uma fusão ou aquisição: </strong> Se a {props.empresa} se envolver em uma fusão, venda de ativos, financiamento, liquidação ou falência, ou na aquisição de todos ou de alguma parte do nosso negócio para outra empresa, podemos compartilhar as informações do usuário com aquela empresa, antes e depois do fechamento da transação.
        </li>
    </ol>
    <p>
        Com as exceções requeridas por lei, nenhuma pessoa pode divulgar, ou expor propositadamente, senhas e/ou cookies gerados para identificar um usuário.
    </p>

    <p>
        <strong>
        III – Armazenamento de Informações
        </strong>
    </p>
    <ol>
        <li>
            Os dados do usuário serão mantidos em local seguro e sigiloso e todas as informações coletadas serão utilizadas para o desenvolvimento dos nossos serviços.
        </li>
        <li>
            Por obrigação legal, devemos armazenar os registros de acesso a aplicações de internet pelo prazo de seis meses (art. 15 do Marco Civil da Internet), podendo ser estendido, por determinação judicial. Manteremos as informações em local sigiloso e controlado, não a utilizando ou tornando pública, salvo com expresso consentimento do Usuário ou mediante determinação do Poder Judiciário.
        </li>
    </ol>

    <p>
        <strong>
        IV – Conteúdo e integrações de terceiros
        </strong>
    </p>
    <p>
        Os serviços prestados por nós podem incluir links de terceiros e resultados de pesquisas, integrações de terceiros ou oferecer um serviço de parceria entre marcas ou de marca de terceiros. Usando esses links, integrações de terceiros e serviços de parceria entre marcas ou de marca de terceiros, o usuário pode estar fornecendo informações (incluindo informações pessoais) diretamente ao terceiro, a nós ou a ambos.
        <br></br>
        Esta Política de Privacidade não se aplica a websites e aplicações fornecidas e controladas por terceiros, mesmo que estejam anunciados como publicidade em nosso website ou aplicativo. Nós não nos responsabilizamos pelas Políticas de Privacidade destas terceiras partes e recomendamos fortemente que o Usuário leia as políticas de privacidade a cada acesso a novo website ou aplicativo.
    </p>

    <p>
        <strong>
             V – Controle do usuário sobre suas informações
        </strong>
    </p>
    <ol>
        <li>
            <strong>Atualizações:</strong> O usuário, ao verificar informações inexatas ou incorretas sobre seu cadastro ou dados, poderá exigir a imediata correção e retificação, no prazo de 5 dias úteis a partir da comunicação (art. 43, §3º da Lei 8.078/90). O contato deverá ser feito através do canal “Contato” (https://{props.site}).
        </li>
        <li>
            <strong>Revogação de Permissões:</strong> O usuário pode revogar seu consentimento sobre a coleta de informações do dispositivo mudando as configurações em seu dispositivo, se ele oferecer essas opções. Se o usuário assim o fizer, determinados serviços podem perder a funcionalidade completa.
        </li>
        <li>
            <strong> Exclusão de Conta:  </strong> Se por algum motivo o usuário quiser deixar de utilizar os serviços da {props.empresa}, basta entrar em contato conosco através do canal “Contato” (https://{props.site}).
        </li>
    </ol>

    <p>
        <strong>
            VI – Mudança das Políticas de Privacidade
        </strong>
    </p>
    <p>
        De acordo com os Termos de Uso, esta Política de Privacidade poderá ser modificada, seja pela inclusão de novas funcionalidades, seja por exigência legal ou judicial. Quando isso ocorrer, enviaremos um e-mail para o endereço eletrônico cadastrado com a notificação de mudança e o inteiro teor das novas Políticas de Privacidade
    </p>
    

 

 



 

        
    </div>
    
  </div>
)

export default Politica
