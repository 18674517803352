import React from 'react'
import Topbar from '../components/topbar/topbar';
import Politica from '../components/politica/politica';
export default class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebar: {
        sidebarState: "hide-sidebar",
        filterState: "hide-filter"
      },
      flag: true
    }
  }
  toggleOffSideBar() {
    this.setState({
      sidebar: {
        sidebarState: "hide-sidebar",
        filterState: "hide-filter",
        
      },
      flag: true
    })
  }
  toggleOnSideBar() {
    this.setState({
      sidebar: {
        sidebarState: "show-sidebar",
        filterState: "show-filter"
      },
      flag: false

      
    })
  }
  teste(){
    console.log("click top bar")
  }
  render(){
    return(
      <div >
        <Topbar toggleOn={this.toggleOnSideBar.bind(this)} toggleOff = {this.toggleOffSideBar.bind(this)} flag ={this.state.flag} page="home"  ></Topbar>
        <Politica id="sobre"  empresa="SF Labs" site="www.sflabs.com.br" ></Politica>   
        {/* <SideBar sidebarStateProps={this.state.sidebar} toggleOff = {this.toggleOffSideBar.bind(this)}></SideBar> */}

      </div>
    )

  }
}